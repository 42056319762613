import React, { Fragment, useEffect, useRef, useState } from 'react';
import logoImg from "../../assets/img/logo1.png"
import CNNavItem from './CNNavItem';
import { useSidebar } from './use-sidebar';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { removeSession } from '../../utils/session';
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from '../../config';
import { setAppUserInfo, setAppUserType } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import SVGIcon from '../Icons/SVGIcons';
import { Link } from 'react-router-dom';
import { getDefaultUrl } from '../../utils/url';
import EllipsisIcon from "../../assets/img/icons/menu/ellipsis.svg";

const SCROLL_STEPS = 3;

const Sidebar = ({
  isOpen,
  path,
  shown,
  toggleNavbar
}) => {
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const { navs } = useSidebar();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [deviceType, setDeviceType] = useState("desktop");
  const [showScrollUpButton, setShowScrollUpButton] = useState(false);
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);
  const [mobileHeight, setMobileHeight] = useState(0);
  const [sMobileHeight, setSMobileHeight] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollRef.current;
      const scrollHeight = scrollContainer.scrollHeight;
      const clientHeight = scrollContainer.clientHeight;
      const scrollTop = scrollContainer.scrollTop;

      setShowScrollUpButton(scrollTop > 16);
      setShowScrollDownButton(scrollTop + clientHeight < scrollHeight - 16);
    };

    const handleWindowResizing = () => {
      const width = window.innerWidth;
      if (width >= 1280) {
        shown(true);
        setDeviceType("desktop");
      } else if (width >= 480) {
        shown(false);
        setDeviceType("tablet");
      } else {
        shown(false);
        setDeviceType("phone");
      }
    }

    handleWindowResizing();
    window.addEventListener('resize', handleWindowResizing);

    setTimeout(() => handleScroll(), 1)
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener("resize", handleWindowResizing)
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const totalNavs = navs.length + 1;
    setMobileHeight(Math.ceil(totalNavs / 5) * 80 - 8);
    setSMobileHeight(Math.ceil(totalNavs / 4) * 80 - 8);
  }, [navs])

  const onSignout = () => {
    confirmAlert({
      title: "Connected North",
      message: "Are you sure you want to sign out?",
      buttons: [
        {
          label: "OK",
          onClick: async () => {
            removeSession(CONNECTEDNORTH_SIGNINFO);
            removeSession(CONNECTEDNORTH_SIGNTYPE);
            window.location.href = "/login";
            dispatch(setAppUserInfo(null));
            dispatch(setAppUserType(null));
          },
        },
        {
          label: "Cancel",
          onClick: () => { },
        },
      ],
    });
  };

  const controlNavShown = () => {
    if (deviceType === "desktop" && isOpen) return;
    shown(false);
  }

  return (
    <Fragment>
      <div
        className="sidebar-wrapper"
        style={{
          zIndex: 99999,
          "--mh": `${mobileHeight}px`,
          "--smh": `${sMobileHeight}px`
        }}
      >
        <div className="sidebar">
          <div className="sidebar-header">
            <div className="sidebar-toggler" onClick={toggleNavbar}>
              <i className="fa fa-bars" />
            </div>
            <div className="logo-img">
              <Link to={`${path}${getDefaultUrl(userType, userInfo)}`}>
                <img alt="Connected North Logo"
                  src={logoImg}
                />
              </Link>
            </div>
          </div>

          <div className="nav-content">
            <div className="main-navs">
              {showScrollUpButton && (
                <div
                  className="nav-scroll up-indicator"
                  onClick={() => scrollRef.current.scrollTo({
                    top: scrollRef.current.scrollTop - 64 * SCROLL_STEPS,
                    behavior: 'smooth',
                  })}
                >
                  <SVGIcon name="nav-up" color="#0f6aa8" />
                </div>
              )}
              <ul ref={scrollRef} className="nav-list">
                {navs.map((nav, i) => (
                  <CNNavItem
                    key={i}
                    path={path}
                    showTooltip={deviceType !== "phone"}
                    {...nav}
                    hideNav={controlNavShown}
                  />
                ))}
                <CNNavItem
                  key="sign-out"
                  label={t("sign-out")}
                  icon="fa fa-sign-out"
                  onClick={onSignout}
                  showTooltip={deviceType !== "phone"}
                  hideNav={controlNavShown}
                />
              </ul>
              {showScrollDownButton && (
                <div
                  className="nav-scroll down-indicator"
                  onClick={() => scrollRef.current.scrollTo({
                    top: scrollRef.current.scrollTop + 64 * SCROLL_STEPS,
                    behavior: 'smooth',
                  })}
                >
                  <SVGIcon name="nav-down" color="#0f6aa8" />
                </div>
              )}
              <div className="open-bottom-nav" onClick={toggleNavbar}>
                <img src={EllipsisIcon} alt="Popup Bottom Nav" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sidebar-dismiss"
        onClick={() => shown(false)}
      />
    </Fragment>
  )
}

export default Sidebar;